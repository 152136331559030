import { Badge, Box, Flex, Heading, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import DateAndTime from "components/DateAndTime/DateAndTime";
import { DetailText } from "components/Text";
import { TRANSACTION_PAGE_MAX_WIDTH } from ".";
import TransactionTypeName from "../TransactionTypeName";
import { useTitle } from "context/titleContext";

function TransactionHeader({ badge, copyableId, date, title = "Transaction", transactionType, updatedDate }) {
  const gray700 = useColorModeValue("gray.700", "gray.200");
  useTitle({ sub1: title, sub2: copyableId });
  const bg = useColorModeValue("white", "transparent");

  return (
    <Box as="header" backgroundColor={bg}>
      <Box maxW={TRANSACTION_PAGE_MAX_WIDTH}>
        <Flex paddingY={2} justifyContent={"space-between"} alignItems={"center"}>
          <Box flex={1}>
            <Flex alignItems="center" gap={3}>
              <Heading as="h1" m={0} fontSize="24px" color={gray700}>
                <TransactionTypeName transactionType={transactionType} />
              </Heading>
              {badge && (
                <Badge colorScheme={badge.color} variant="solid">
                  {badge.label}
                </Badge>
              )}
            </Flex>

            <CopyableText text={copyableId}>
              <DetailText>{copyableId}</DetailText>
            </CopyableText>
          </Box>
          <Stack gap={4} direction={{ base: "column-reverse", lg: "row" }}>
            <Flex gap={"2px"} direction="column" fontSize={"10px"}>
              <Flex>
                <Text mr={1} as="span">
                  Created:
                </Text>
                <DateAndTime value={date} />
              </Flex>
              {updatedDate && (
                <Flex>
                  <Text mr={1} as="span">
                    Updated:
                  </Text>
                  <DateAndTime value={updatedDate} />
                </Flex>
              )}
            </Flex>

            {/* This button grows on mobile */}
            {/* TODO: Pass in an array of actions like Review for a Payout https://tiliajira.atlassian.net/browse/DEV-3522 */}
            {/* <Button variant="outline" colorScheme="blue" size="sm">
              Review
            </Button> */}
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}

export default TransactionHeader;
