import {
  Card,
  CardBody,
  Table,
  TableContainer,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import { Money } from "components/Money";
import { AccountLink } from "components/Account";
import { DetailText } from "components/Text";
import BalanceTransferItemTypeName from "./BalanceTransferItemTypeName";

function BalanceTransferItems({ subItems }) {
  if (subItems.length < 1) {
    return null;
  }
  return (
    <Card>
      <CardBody>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Source</Th>
                <Th>Type / Amount</Th>
                <Th>Destination</Th>
              </Tr>
            </Thead>
            <Tbody>
              {subItems.map((subItem) => (
                <Tr key={subItem.subItemId}>
                  <Td>
                    <AccountLink account={subItem.sourceAccount} includeAvatar={true}>
                      {subItem.sourceAccount.username || subItem.sourceAccount.accountId}
                    </AccountLink>
                    {subItem.sourceWallet && (
                      <Text>
                        {subItem.sourceWallet.description}
                        <br />
                        <DetailText>
                          <CopyableText text={subItem.sourceWallet.id} />
                        </DetailText>
                      </Text>
                    )}
                  </Td>
                  <Td>
                    <BalanceTransferItemTypeName balanceTransferItemType={subItem.subItemType} />
                    <br />
                    <Money value={subItem.amount} currency={subItem.currency} />
                  </Td>
                  <Td>
                    <AccountLink account={subItem.destinationAccount} includeAvatar={true}>
                      {subItem.destinationAccount.username || subItem.destinationAccount.accountId}
                    </AccountLink>
                    {subItem.destinationWallet && (
                      <Text>
                        {subItem.destinationWallet.description}
                        <br />
                        <DetailText>
                          <CopyableText text={subItem.destinationWallet.id} />
                        </DetailText>
                      </Text>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}

export default BalanceTransferItems;
