import React, { useEffect } from "react";
import { Text, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { exists, isNilOrEmpty } from "utils";
import { WalletTable } from "./WalletTable";
import useAccountWallets from "./useAccountWallets";
import { WalletBalance } from "./WalletBalance";
import { SectionSpinner } from "components/Spinner";

const sortByCurrencyAndDescription = (a, b) => {
  // float USD to the top / front
  if (a.currency === "USD" && b.currency !== "USD") {
    return -1;
  }
  if (b.currency === "USD" && a.currency !== "USD") {
    return 1;
  }

  // sort by currency, then description
  if (a.currency === b.currency) {
    return a.description.localeCompare(b.description);
  }
  return a.currency.localeCompare(b.currency);
};

export const WalletTabs = () => {
  const { accountId, walletId } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useAccountWallets(accountId);

  const wallets = data?.account?.wallets || [];
  const nonHOSWallets = wallets.filter((wallet) => !wallet.tags.includes("hos"));
  const finalWallets = nonHOSWallets.sort(sortByCurrencyAndDescription);

  // walletId is optional, find returns -1 if not found - default to first [0] tab active
  const activeIndex = Math.max(
    finalWallets.findIndex((wallet) => wallet.id === walletId),
    0
  );

  // if called with no walletId, redirect to first wallet url
  useEffect(() => {
    if (exists(finalWallets) && isNilOrEmpty(walletId)) {
      navigate(`./${finalWallets[0].id}`);
    }
  }, [finalWallets, navigate, walletId]);

  // update the URL when the tab changes
  const handleTabChange = (index) => {
    navigate(`../${finalWallets[index].id}`);
  };

  if (!loading && isNilOrEmpty(finalWallets)) {
    return <Text>No wallets found</Text>;
  }
  if (loading) return <SectionSpinner />;

  return (
    <Tabs variant="enclosed" isLazy onChange={handleTabChange} defaultIndex={activeIndex}>
      <TabList>
        {finalWallets.map((wallet) => (
          <Tab key={wallet.id}>{wallet.description}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {finalWallets.map((wallet) => (
          <TabPanel key={wallet.id}>
            <WalletBalance balance={wallet?.currentBalance} currency={wallet?.currency} />
            <WalletTable wallet={wallet} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
