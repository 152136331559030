import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Wrap,
} from "@chakra-ui/react";
import * as R from "ramda";
import { CopyableText } from "components/Copyable";
import DetailList from "components/DetailList/DetailList";
import { DotsVerticalIcon } from "components/Icons";
import { DetailText } from "components/Text";
import VerificationBadge from "components/VerificationBadge/VerificationBadge";

const STATUS_COLOR_MAP = {
  critical: "red",
  info: "gray",
  warning: "yellow",
};
const getColorScheme = (status) => R.defaultTo(STATUS_COLOR_MAP["info"], STATUS_COLOR_MAP[status]);

/**

    MethodCard component props
    @typedef {Object} MethodCardProps
    @property {Array<{label: string, status: 'critical' | 'info'}>} [tags=[]] - List of tags to be displayed.
    @property {{label: string, status: 'critical' | 'info'}} badge - Status of card rendered as Badge.
    @property {string} title - Title of the card.
    @property {string} paymentId - Payment ID.
    @property {Array<{label: string}>} [actionItems=[]] - List of action items to be displayed.
    @property {Array<{title: string, detail: string}>} [detailList=[]] - List of details to be displayed.
    @property {ReactNode} [children] - Child components to be displayed.
    */

/**

    MethodCard component
    @param {MethodCardProps} props - MethodCard component props.
    @returns {JSX.Element}
    */

export default function MethodCard({
  actionItems = [],
  badge,
  detailList = [],
  paymentId,
  tags = [],
  title,
  verified,
  children,
}) {
  return (
    <Card>
      <CardHeader>
        <Flex justifyContent={"space-between"}>
          {title}
          {actionItems.length > 0 && (
            <Menu>
              <MenuButton
                data-testid="card-menu-button"
                variant="ghost"
                cursor="pointer"
                // TODO: remove transparent if possible in Chakra 2
                bg={"transparent"}
                aria-label="Actions menu"
                as={IconButton}
                icon={<DotsVerticalIcon />}
              />
              <MenuList>
                {actionItems.map(({ label, ...props }) => (
                  <MenuItem key={label} {...props}>
                    {label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
        </Flex>
      </CardHeader>
      <CardBody display="flex" flexDirection="column" gap={4}>
        {tags.length > 0 && (
          <Wrap>
            {tags.map(({ label, status }) => (
              <Tag key={label} colorScheme={getColorScheme(status)}>
                {label}
              </Tag>
            ))}
          </Wrap>
        )}
        {badge && (
          <Badge alignSelf="flex-start" colorScheme={STATUS_COLOR_MAP[badge.status]}>
            {badge.label}
          </Badge>
        )}
        {verified && <VerificationBadge />}

        {detailList.length > 0 && (
          <DetailList details={detailList.map(({ title, detail }) => [title, detail])} />
        )}
        <Box flex="1">{children}</Box>
        <Box flex="none">
          <CopyableText text={paymentId}>
            <DetailText>{paymentId}</DetailText>
          </CopyableText>
        </Box>
      </CardBody>
    </Card>
  );
}
