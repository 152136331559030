import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { Money } from "components/Money";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import MethodCard from "./MethodCard";
import { CopyableText } from "components/Copyable";
import { DetailText } from "components/Text";
import { DataOrNo } from "./common";

const STATUS_MAP = {
  INACTIVE: "critical",
  UNVERIFIED: "warning",
};
export const Wallets = ({ data = [] }) => {
  return (
    <Panel>
      <PanelTitle>Wallets ({data.length})</PanelTitle>
      <PanelContent>
        <DataOrNo data={data}>
          <Box maxW={"1600px"}>
            <SimpleGrid
              columns={{
                base: 1,
                lg: 2,
                xl: 3,
                "2xl": 4,
              }}
              spacing={8}
            >
              {data.map(
                ({
                  display_string,
                  id,
                  pm_state,
                  processing_currency,
                  provider_data,
                  tags,
                  wallet_balance = 0,
                }) => (
                  <MethodCard
                    key={id}
                    title={
                      <div>
                        <Heading as="h3" size="sm" m={0}>
                          {display_string}
                        </Heading>
                        <CopyableText text={provider_data?.wallet_id}>
                          <DetailText>{provider_data?.wallet_id}</DetailText>
                        </CopyableText>
                      </div>
                    }
                    badge={
                      pm_state !== "ACTIVE"
                        ? { label: pm_state, status: STATUS_MAP[pm_state] }
                        : null
                    }
                    paymentId={id}
                    detailList={[
                      {
                        title: "Balance",
                        detail: <Money value={wallet_balance} currency={processing_currency} />,
                      },
                    ]}
                    tags={tags.map((tag) => ({
                      label: tag.tag,
                      status: "info",
                    }))}
                  />
                )
              )}
            </SimpleGrid>
          </Box>
        </DataOrNo>
      </PanelContent>
    </Panel>
  );
};
